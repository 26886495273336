input[type=search] {
    background-color: rgba(40, 40, 40, 1);

    border: 1px solid rgba(60, 60, 60, 1);
    border-radius: 3px;

    background-position: right;
    background-repeat: no-repeat;
    background-blend-mode: overlay;

    font-family: 'Cinzel';
    text-align: center;

    color: var(--text-color-hover);
}

#mainSearch {
    padding: 10px;
    width: 100%;

    background-image: linear-gradient(rgba(130, 130, 130, 1), rgba(160, 160, 160, 1)), url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><path d="M31 28h-1.59l-.55-.55C30.82 25.18 32 22.23 32 19c0-7.18-5.82-13-13-13S6 11.82 6 19s5.82 13 13 13c3.23 0 6.18-1.18 8.45-3.13l.55.55V31l10 9.98L40.98 38 31 28zm-12 0c-4.97 0-9-4.03-9-9s4.03-9 9-9 9 4.03 9 9-4.03 9-9 9z"/></svg>');
    font-size: 40px;
}

#tagRefineSearch {
    background-image: linear-gradient(rgba(130, 130, 130, 1), rgba(160, 160, 160, 1)), url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>');
}

.cogitator {
    font-family: 'Cinzel';
}

.cogitator a {
    text-decoration: none;
    color: inherit;
}
.cogitator a:hover {
    text-decoration: underline;
}
.cogitator a.active {
    text-decoration: none;
}

.refinementActive {
    font-weight: bold;
    color: var(--text-color-hover);
}

.refinementInactive {
    font-weight: normal;
    color: var(--text-color);
}

.cogitator ul {
    text-align: center;
    list-style: none;
    padding: 0;
}

.cogitator li {
    list-style: none;
    display: inline;
}

.cogitator li:after {
    content: " \00b7  ";
}

.cogitator-top-row {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 40px;

}

.main-search-div {
    flex-grow: 1;
}

.button-add-new {
    margin-left: 15px;

    background-color: rgb(117, 17, 17);
    background-image: linear-gradient(rgb(117, 17, 17), rgb(85, 11, 11));

    border-radius: 2px;

    max-width: 300px;
    border: none;
    padding: 5px 15px;
    text-decoration: none;
    cursor: pointer;
    color: antiquewhite;
    font-family: 'Cinzel';
    font-size: 30px;
}

.button-add-new:hover {
    background-image: linear-gradient(rgb(146, 11, 11), rgb(117, 11, 11));
}

.button-add-new:active {
    background-image: linear-gradient(rgb(85, 11, 11), rgb(117, 17, 17));
}


.submit-form-wrapper {
    transition: height 0.5s;
    overflow: hidden;
}
