
.quotation-wrapper {
    transition: height 0.5s;
    overflow: hidden;

    margin: auto;
    margin-top: 60px;
    margin-bottom: 20px;
    text-align: center;
}


@keyframes fade-in-anim {
	0% {opacity: 0;}
	100% {opacity: 1;}
}
.quotation {
	animation-name: fade-in-anim;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
    animation-duration: 1s;
    animation-fill-mode: backwards;

    color: var(--text-color);
    transition: color 0.5s ease;
}

.quotation:hover {
    color: var(--text-color-hover);
    transition: color 0.5s ease;
}


.quotation q {
    display: block;
    font-family: 'Cinzel';
    font-size: 30px;
    padding: 10px;
}

.quotation > cite {
    display: block;
    font-family: 'Cinzel';
    font-style: normal;
    font-size: 16px;
    margin: 2px 20px;
    text-align: right;
}



.quotation a {
    text-decoration: none;
    color: rgba(var(--text-color), 0.8);
}
.quotation a:hover {
    color: rgba(var(--text-color-hover), 0.8);
    text-decoration: underline;
}
.quotation a.active {
    text-decoration: none;
}


.quote-details {
    display: flex;
    flex-flow: row wrap;

    margin-top: 15px;
    color: antiquewhite;
}

.quote-details span {
    flex-basis: 49%;
    text-align: right;
}

.quote-details > * {
    font-size: 16px;
    font-family: 'Cinzel';
}

.quote-details cite {
    flex-basis: 49%;

    font-style: normal;
    text-align: right;
}

.quote-details ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
  }

.quote-details li {
    padding-left: 3px;
}

.quote-details li:after {
    content: " \00b7";
}

.quote-details li:last-child:after {
    content: none;
}
