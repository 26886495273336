:root {
    --text-color: antiquewhite;
    --text-color-hover: rgb(179, 32, 32);
}

body {
    padding: 0;
    margin: 0;
    background-color: rgba(40,40,40);
    font-family: 'Cinzel';
    color: antiquewhite;
}

#page-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: start;

    max-width: 1000px;
    margin: auto;

    position: relative;
    min-height: 100vh;
    padding: 0;
}


footer {
    margin: auto;
    margin-bottom: 0px;
    margin-top: 90px;
    justify-self: end;
    font-size: 10px;
    color: antiquewhite;
    font-family: 'Cinzel';
    text-align: center;


}
footer a {
    color: rgba(var(--text-color), 0.8);
    font-family: 'Cinzel';
    text-decoration: none;
}
footer a:hover {
    text-decoration: underline;
}
footer a.active {
    text-decoration: none;
}


.no-results {
    display: block;
    width: 50%;
    margin: auto;
    margin-top: 20px;
    text-align: center;
}

@keyframes servoskull-float-anim {
    0%   {bottom:30px;}
    50%  {bottom:50px;}
    100% {bottom:30px;}
}
#skull {
    float: right;
    max-width: 200px;
    height: auto;
    position: fixed;
    right: 20px;

    animation-name: servoskull-float-anim;
    animation-duration: 7s;
    animation-iteration-count: infinite;
}

.ais-InfiniteHits ul {
    list-style-type: none;
    padding: 0;
}


.ais-InfiniteHits-loadMore, .button-small {
    display: block;

    margin: 5px auto;

    background-color: rgb(117, 17, 17);
    background-image: linear-gradient(rgb(117, 17, 17), rgb(85, 11, 11));

    border-radius: 2px;

    max-width: 300px;
    border: none;
    padding: 5px 15px;
    text-decoration: none;
    cursor: pointer;
    color: antiquewhite;
    font-family: 'Cinzel';
    font-size: 16px;
}

.ais-InfiniteHits-loadMore:hover, .button-small:hover {
    background-image: linear-gradient(rgb(146, 11, 11), rgb(117, 11, 11));
}

.ais-InfiniteHits-loadMore:active, .button-small:active {
    background-image: linear-gradient(rgb(85, 11, 11), rgb(117, 17, 17));
}



@media screen and (max-width: 1400px) {
    /* hide on mobile */
   .mobile-hide {
      display: none !important;
   }
}
